import React,  {useState, useEffect} from 'react';

import { useParams } from 'react-router';

import Grid from '@mui/material/Grid';

import "./style/studiospec.css";

import "./style/informacoes.css";



const StudioSpec = () => {


    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    let componentMounted = true; 
    const studio = useParams();

    let metragemUm;
    let metragemDois = "";
    let metragemtres = "";
    let metragemQua = "";

    let titulo;
    let texto = [];


    useEffect(() => {
        console.log(studio.studio)

        const getStudios = async () => {
 
        }
        getStudios();

    }, []);
             

    
  
      
    const ShowStudios = () => {
        let timer = 0;
        if(studio.studio == 1) { titulo = "studio 101";texto[0] = "studio 101(Metragem privativa:19,04m²)  disponível + garden "; }
        if(studio.studio == 2) {titulo = "studios 102";texto[0] = "studio 102(Metragem privativa:27,46m²)  disponível + garden "; }
        if(studio.studio == 3) {titulo = "studio 103";texto[0] = "studio 103(Metragem privativa:19,31m²)  disponível + garden ";   }
        if(studio.studio == 5) {titulo = "studio 201";texto[0] = "studio 201(Metragem privativa:35,95m²)  disponível + sacada com churrasqueira";}
        if(studio.studio == 4) {titulo = "studio 202";texto[0] = "studio 202(Metragem privativa:27,09m²)  disponível + sacada com churrasqueira";}
        if(studio.studio == 7) {titulo = "studio 301";texto[0] = "studio 301(Metragem privativa:23,63m²)  disponível + garden + sacada com churrasqueira";}
        if(studio.studio == 6) {titulo = "studios 302 | 402 | 502";texto[0] = "studio 302(Metragem privativa:25,04m²) vendido";texto[1] = "studio 402(Metragem privativa:25,28m²)  disponível + sacada com churrasqueira"; texto[2] = "studio 502(Metragem privativa:25,28m²) disponível + sacada com churrasqueira";}
        if(studio.studio == 8) {titulo = "studios 303 | 403";texto[0] = "studio 303(Metragem privativa:32,97m²)  disponível + garden + sacada com churrasqueira";texto[1] = "studio 403(Metragem privativa:32,97m²)  disponível + sacada com churrasqueira"; };
        if(studio.studio == 9) {titulo = "studio 404";texto[1] = "studio 404(Metragem privativa:29,31m²)  disponível + sacada com churrasqueira"; };
        if(studio.studio == 10) {titulo = "studio 601";texto[0]="studio 601(Metragem privativa:24,75m²)  disponível + garden + sacada com churrasqueira";}
        if(studio.studio == 11) {titulo = "studio 702";texto[0]="studio 702(Metragem privativa:21,61m²)"; }
        if(studio.studio == 12) {titulo = "studio 703";texto[0]="studio 703(Metragem privativa:17,18m²)  "; };
        return (
            <div className="studiostudio">
                
                <div className="imagens_studio">

                <img src={"/" + studio.studio+"/1.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/2.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/3.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/4.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/5.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/6.jpg" } data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                    
                
                </div>
                <div className="informacoes_dad">
                <div className="informacoes" style={{alignContent:'space-around'}}>
                    <h1>{titulo}</h1>

                    <p> 
                       {texto[0]}
                       <br/>
                       {texto[1]}
                       <br/>
                       {texto[2]}
                       <br/>
                       {texto[3]}
                    </p>
                  
                    <a style={{margin: '0 auto 0px auto'}}className="custom-btn btn-15 aos-init aos-animate" href={"https://api.whatsapp.com/send?phone=5541996480900"}>
                        saber mais
                    </a>

                   
                </div>
                </div>
            </div>
        )
    }

    const Loading = () => {
        return (
            <>
                Carregando...
            </>
        )
    }
  

    return (
        <div>
            {loading ? <Loading/> : <ShowStudios/>}
        </div>
    )
}

export default StudioSpec;