import React from "react";

import "./style/arquitetura.css";

const Arquitetura = () =>  {
    return (
        <div className="Arquitetura">
        
        
        <div className="Arquitetura_container" data-aos="fade-up" data-aos-duration="1000">
            <h5>ARQUITETURA</h5>
            <h6>VINICIUS GUIMARÃES <br/> ARQUITETOS</h6>
            <p>
                Contemporaneidade, versatilidade e conforto. 
                Esses foram os pilares para o projeto do SANTI,
                o mais novo empreendimento no bairro Mercês. 
                O foco central foi traduzir o estilo de vida
                que busca a praticidade e o bem-estar, mas
                com um design arrojado, com destaques em 
                elementos de concreto aparente. Já as curvas 
                da volumetria trazem a suavidade e o equilíbrio
                ao projeto. A configuração dos apartamentos
                prioriza a funcionalidade da vida moderna.
                De mesmo modo, as plantas compactas foram
                pensadas para trazer praticidade e conforto.
                As áreas comuns do prédio são verdadeiras 
                extensões dos apartamentos, tendo como objetivo
                reunir, em um só lugar, espaços de lazer,
                trabalho e serviços, atendendo às necessidades 
                dos moradores.
            </p>
        </div>
    </div>
    )
}

export default Arquitetura;